<template>
  <div class="st-wrapper shop-list-wrapper">
    <header-search placeholder="输入店铺名称搜索" @search="searchShop" />

    <ul class="shop-items" v-if="shopList.length > 0">
      <li
        v-for="item in shopList"
        :key="item.shop_id"
        class="st-item"
        @click="onShopItem(item)"
      >
        <div class="item-main">
          <h3>{{ item.shop_name || '暂无店铺名' }}</h3>
          <s>{{ item.shop_add || '暂无档口号' }}</s>
        </div>
        <div class="item-sub">
          <span>联系方式：{{ item.link_phone }}</span>
          <b>
            <span>详情</span>
          </b>
        </div>
      </li>
    </ul>

    <empty
      icon="shop"
      text="此市场暂无经营户"
      btn-text="重新选择市场"
      height="calc(100vh - 3rem)"
      @btn-tap="goMarkets"
      v-else
    />
  </div>
</template>

<script>
import HeaderSearch from '../../../components/header/search/Index'
import Empty from '../../../components/empty/Index'
import { mapState } from 'vuex'
import _ from 'lodash'

export default {
  name: '',
  components: {
    HeaderSearch,
    Empty
  },
  data () {
    return {
      shopList: []
    }
  },
  computed: {
    ...mapState(['shopItems'])
  },
  mounted () {
    this.getShopItems()
  },
  methods: {
    // 获取店铺列表
    getShopItems () {
      this.$api.shops.list({
        farmers_market_id: this.$route.query.marketId,
        page_no: 1,
        page_size: 1000
      }).then(res => {
        if (res.status === 200) {
          this.shopList = res.data.data
          this.$store.dispatch('setShopItems', this.shopList)
        }
      })
    },

    // 搜索店铺
    searchShop (value) {
      if (value) {
        this.shopList = _.compact(_.map(this.shopItems, item => {
          if (item.shop_name && item.shop_name.indexOf(value) > -1) {
            return item
          }
        }))
      } else {
        this.shopList = this.shopItems
      }
    },

    // 点击店铺项
    onShopItem (item) {
      this.$router.push({
        name: 'Management',
        query: {
          marketId: this.$route.query.marketId,
          marketMerchantId: item.farmers_market_merchant_id,
          shopId: item.shop_id,
          shopName: item.shop_name
        }
      })
    },

    // 回到市场列表页
    goMarkets () {
      this.$router.push({ name: 'Markets' })
    }
  }
}
</script>

<style lang="less" scoped>
@import './index';
</style>
